<template>
  <modal
    :width="530"
    :adaptive="true"
    class="cancellationModal CEModal modal-scroll-bar"
    name="cancellationModal"
  >
    <div class="modal-content">
      <div v-if="isCancelPermission && isRavkavModal" class="d-flex justify-content-center cancelltion-option-group m-b-20">
        <button class="primary-btn" @click="setCancelOption(1)" :class="cancelOption==1 ? 'active' : ''">
          {{ $t('CANCELLATION.CANCEL_VIA_PROVIDER') }}
        </button>
        <button class="primary-btn" @click="setCancelOption(2)" :class="cancelOption==2 ? 'active' : ''">
          {{ $t('CANCELLATION.CANCEL_LOCALY') }}
        </button>
      </div>
      <div class="d-flex justify-content-between m-b-15">
        <h5 class="weight-700">{{ $t('CANCELLATION.TRANSACTION') }} {{ cancellationItem.transaction_id }}</h5>
        <h5 class="weight-700">{{ $t('COMMON.PRICE') }}: {{ cancellationItem.price }} <span class="fs16">₪</span></h5>
      </div>
      <div class="cancellation-summary">
        <div class="d-flex flex-wrap m-b-5">
          <p class="fs14 field-title">{{ $t('GIFTCARD_PAYMENT_RESPUB.BUSINESS_NAME') }}</p>
          <p class="fs14 mx10"><b>{{ cancellationItem.business_name }}</b></p>
        </div>
        <div class="d-flex flex-wrap m-b-5">
          <p class="fs14 field-title">{{ $t('CANCELLATION.BUSINESS_ID') }}</p>
          <p class="fs14 mx10"><b>{{ cancellationItem.business_id }}</b></p>
        </div>
        <div class="d-flex flex-wrap m-b-5">
          <p class="fs14 field-title">{{ $t('CANCELLATION.PROVIDER') }}</p>
          <p class="fs14 mx10"><b>{{ cancellationItem.supplier_name }}</b></p>
        </div>
        <div class="d-flex flex-wrap m-b-5">
          <p class="fs14 field-title">{{ $t('DASHBOARD.PRODUCT_DESCRIPTION') }}</p>
          <p class="fs14 mx10"><b>{{ cancellationItem['product_name_' + this.currentLang] }}</b></p>
        </div>
        <div class="d-flex flex-wrap m-b-5">
          <p class="fs14 field-title">{{ $t('CANCELLATION.CHARGING_TIME') }}</p>
          <p class="fs14 mx10"><b>{{ getDayFromTimeStamp(cancellationItem.transaction_end_timestamp) }} {{ cancellationItem.transaction_end_timestamp | formatDate }} {{ cancellationItem.transaction_end_timestamp | formatTime }}</b></p>
        </div>
        <div class="d-flex flex-wrap m-b-5">
          <p class="fs14 field-title">{{ $t('CANCELLATION.CELLULAR_NUMBER') }}</p>
          <p class="fs14 mx10"><b>{{ cancellationItem.phone_number }}</b></p>
        </div>
        <div class="d-flex flex-wrap m-b-5" v-if="isRavkavModal">
          <p class="fs14 field-title">{{ $t('CANCELLATION.RAVKAV_CARD') }}</p>
          <p class="fs14 mx10"><b>{{ cancellationItem.card_number }}</b></p>
        </div>
        <div class="d-flex flex-wrap m-b-5" v-if="isRavkavModal">
          <p class="fs14 field-title">{{ $t('CANCELLATION.RAVKAV_TRANSACTTION') }}</p>
          <p class="fs14 mx10"><b>{{ cancellationItem.confirmation }}</b></p>
        </div>
      </div>
      <hr v-if="!isRavkavModal || cancelOption==2">
      <div v-if="isSubmited && submitState == 'success'" class="m-b-10 fs23">
        <b>{{ $t('CANCELLATION.CANCELATION_SUCCESSFUL') }}</b>
      </div>
      <div v-else-if="isSubmited && submitState == 'partial'" class="m-b-10 fs23">
        <b>{{ $t('CANCELLATION.PARTIAL_CANCELATION_OF_NUM', {NUM: cancellation_modal.cancelAmount}) }}</b>
      </div>
      <div v-else-if="isSubmited && submitState == 'error'" class="m-b-10 fs23">
        <b>{{ submitMessage || $t('CANCELLATION.CAN_NOT_BE_CANCELED') }}</b>
      </div>
      <div v-if="!isRavkavModal || cancelOption==2">
        <div class="m-b-10">
          <label class="d-block mb-0 fs15">{{ $t('CANCELLATION.CANCEL_CONFIRMATION_NUMBER') }}</label>
          <input 
            type="text" 
            class="input" 
            v-model="cancellation_modal.cancelConfirmationNumber"
            :readonly="isSubmited" 
          >
        </div>
        <div class="m-b-10">
          <label class="d-block mb-0 fs15">{{ $t('CANCELLATION.CANCELLATION_AMOUNT') }}</label>
          <input 
            type="number"   
            class="input w-100" 
            min="0"  
            :max="cancellationItem.price" 
            v-model="cancellation_modal.cancelAmount" 
            @keypress="isNumber($event)"
            :readonly="isSubmited"
          >
        </div>
        <div class="m-b-10">
          <label class="d-block mb-0 fs15">{{ $t('COMMON.COMMENTS') }}</label>
          <textarea
            rows="3"
            class="w-100 bg-white px10 bdbr-2"
            v-model="cancellation_modal.cancellationNote"
            :readonly="isSubmited"
          >
          </textarea>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="primary-btn mx10 bg-red2 fs16" @click="submitCancellation" v-if="(isRavkavModal && cancelOption==1 && !isSubmited) || (!isSubmited && cancellation_modal.cancelAmount && cancellation_modal.cancelAmount <= cancellationItem.price)">
        {{ $t('COMMON.CANCEL') }}
      </button>
      <button class="primary-btn mx10 fs16 close-btn" @click="closeModal()">
        {{ $t('COMMON.CLOSE') }}
      </button>
    </div>
  </modal>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "CancellationModal",
  props: {
    cancellationItem: {
      require: true,
      type: Object      
    },
    isRavkavModal: {
      require: true,
      type: Boolean      
    },
    isCancelPermission: {
      require: true,
      type: Boolean      
    },
  },
  data() {
    return {
      cancellation_modal: {
        transaction_id: "",
        transactionId: "",
        cancelConfirmationNumber: "",
        cancelAmount: "",
        cancellationNote: ""
      },
      isSubmited: false,
      submitState: "",
      submitMessage: null,
      cancelOption: 1
    }
  },
  methods: {
    ...mapActions("report", {
      cancel_payment: "cancel_payment"
    }),
    closeModal() {
      this.$modal.hide('cancellationModal');
      this.isSubmited = false;
      document.querySelector('.cancellationModal .vm--modal').style.backgroundColor = "#fff";
    },
    submitCancellation() {
      this.cancellation_modal.transaction_id = this.cancellationItem.transaction_id;
      this.cancellation_modal.transactionId = this.cancellationItem.transaction_id;
      let params = this.cancellation_modal;
      params.internal = true;
      if (this.isRavkavModal && this.cancelOption==1) {
        delete params.internal; 
        this.cancellation_modal.cancelAmount = this.cancellationItem.price;
      }
      const supplierId = this.cancellationItem.supplier_id;
      this.cancel_payment({ supplierId, params }).then(
        res => {
          this.isSubmited = true;
          if (res && typeof res === 'object' && 'success' in res && res.success) {
            if (this.cancellation_modal.cancelAmount == this.cancellationItem.price) {
              document.querySelector('.cancellationModal .vm--modal').style.backgroundColor = "#E4FFE9";
              this.submitState = "success";
            } else {
              document.querySelector('.cancellationModal .vm--modal').style.backgroundColor = "#FFFDDB";
              this.submitState = "partial";
            } 
          } else {
            document.querySelector('.cancellationModal .vm--modal').style.backgroundColor = "#FFE6E6";
            this.submitState = "error";
            if (res
              && typeof res === 'object'
              && 'response' in res
              && res.response
              && typeof res.response === 'object'
              && 'data' in res.response
              && res.response.data
              && typeof res.response.data === 'object'
              && 'error' in res.response.data
              && res.response.data.error) {
              this.submitMessage = res.response.data.error;
            }
          }
          this.$emit('refilter');
        }
      );
    },
    setCancelOption(val) {
      this.cancelOption = val;
    }
  }

}
</script>
<style lang="scss">
.cancellationModal {
  .vm--modal {
    max-height: unset !important;
  }
}
</style>

<style lang="scss" scoped>
.cancellationModal {
  input:read-only,
  textarea:read-only {
    background-color: #f4f4f4 !important;
  }
  .modal-content {
    font-size: 16px;
    padding: 30px;
    background-color: unset;
    border-radius: unset;
    padding-bottom: 10px;
  }
  .modal-footer {
    justify-content: center;
    padding: 20px;
    border-top: 1px solid #dedede;
  }
  .primary-btn.close-btn {
    max-width: 150px;
  }
  .cancellation-summary {
    .field-title {
      width: 180px;
      margin-bottom: 0;
    }
  }
  .cancelltion-option-group {
    .primary-btn {
      background-color: #D6D6D6;
      color: #788791;
      width: 48%;
      font-size: 16px;
      &.active {
        background-color: #0D3856;
        color: #fff;
      }
    }
  }
}
</style>