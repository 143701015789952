<template>
  <main class="translation page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="lang-title weight-700">{{ $t("REPORT_SALES.SALES_REPORT") }}</p>
    <div class="filter-part weight-700">
      <h5 class="weight-700 m-b-10">{{ $t("REPORT_SALES.TIME_FILTERING") }}</h5>
      <div class="d-flex align-items-center flex-wrap">
        <div>
          <b-row class="m-b-10">
            <b-col lg="5" class="d-flex align-items-center p10 space-nowrap">
              {{ $t("REPORT_SALES.START_DATE") }} :
            </b-col>
            <b-col lg="7" class="d-flex align-items-center p10">
              <Datepicker 
                v-model="filter.start_date" 
                :format="format"
                class="input tour-person__input cus-datepicker"
                :language="languages[currentLang]"
              ></Datepicker>
              <img src="/assets/img/calander.png" alt="" class="calendar-img">
            </b-col>
          </b-row>
          <b-row class="m-b-10">
            <b-col lg="5" class="d-flex align-items-center p10 space-nowrap">
              {{ $t("REPORT_SALES.END_DATE") }} :
            </b-col>
            <b-col lg="7" class="d-flex align-items-center p10">
              <Datepicker 
                v-model="filter.end_date" 
                :format="format"
                class="input tour-person__input cus-datepicker"
                :language="languages[currentLang]"
              ></Datepicker>
              <img src="/assets/img/calander.png" alt="" class="calendar-img">
            </b-col>
          </b-row>
        </div>
        <div class="time-part">
          <b-row class="m-b-10">
            <b-col lg="4" class="d-flex align-items-center p10 space-nowrap">
              {{ $t("REPORT_SALES.START_HOUR") }} :
            </b-col>
            <b-col lg="8" class="d-flex align-items-center p10">
              <vue-select 
                :options="filter.start_hours.options" 
                class="input cus-select time-select mx5"
                v-model="filter.start_hours.selected"
              >
              </vue-select> 
              <span>:</span> 
              <vue-select 
                :options="filter.start_mins.options" 
                class="input cus-select time-select mx5"
                v-model="filter.start_mins.selected"
              >
              </vue-select>
            </b-col>
          </b-row>
          <b-row class="m-b-10">
            <b-col lg="4" class="d-flex align-items-center p10 space-nowrap">
              {{ $t("REPORT_SALES.END_HOUR") }} :
            </b-col>
            <b-col lg="8" class="d-flex align-items-center p10">
              <vue-select 
                :options="filter.end_hours.options" 
                class="input cus-select time-select mx5"
                v-model="filter.end_hours.selected"
              >
              </vue-select> : 
              <vue-select 
                :options="filter.end_mins.options" 
                class="input cus-select time-select mx5"
                v-model="filter.end_mins.selected"
              >
              </vue-select>
            </b-col>
          </b-row>
        </div>
        <div class="range-part">
          <!-- <div class="m-b-10 sales2-range-date flex-wrap">
            <label for="" class="space-nowrap">{{ $t("REPORT_SALES.RANGE_OF_DATES") }}</label>
            <date-range-picker v-model="filter.dateRange" class="range-datepicker">
            </date-range-picker>
          </div> -->
          <div class="m-b-10 flex-wrap">
            <label for="" class="space-nowrap">{{ $t("REPORT_SALES.RANGE_OF_DATES") }}</label>
            <vue-select 
              :options="filter.dateRange.options" 
              class="input cus-select payment-form_select no-uppercase-select"
              v-model="filter.dateRange.selected"
              @input="changeDateRange"
            >
            </vue-select> 
          </div>
          <div class="m-b-10 d-flex align-items-center">
            <v-checkbox v-model="filter.isTimeLimit" color="#0D3856" class="cus-checkbox pt5 mx5 inline-checkbox"></v-checkbox>
            <label for="" class="m-0">{{ $t("REPORT_SALES.WITHOUT_TIME_LIMITS") }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="filter-part m-t-10 weight-700">
      <h5 class="weight-700 m-b-10">{{ $t("REPORT_SALES.GENERAL_FILTERING") }}</h5>
      <div class="d-flex align-items-center flex-wrap">
        <div class="filter-item d-flex align-items-center m-b-10">
          <label class="space-nowrap">
            {{ $t("REPORT_SALES.SUPPLIER_ID") }} :
          </label>
          <vue-select
              :options="suppliersOptionsList"
              :reduce="suppliersOption => suppliersOption.id"
              label="name"
              class="input cus-select mx10 no-uppercase-select payment-form_select"
              v-model="filter.supplier_id"
          >
          </vue-select>
        </div>
        <div class="filter-item m-b-10 d-flex align-items-center">
          <label class="m-0 space-nowrap">
            {{ $t("TOURISM_PROFIT_PERCENTAGES.MOBILE_PHONE") }} :
          </label>
          <div class="d-flex align-items-center">
            <input class="input tour-person__input filter-width mx10" type="text" v-model="filter.phone" />
            <div class="icon-div">
              ?
            </div>
          </div>
        </div>
        <div class="filter-item m-b-10 d-flex align-items-center">
          <label class="m-0 space-nowrap">
            {{ $t("REPORT_SALES.NUMBER_OF_TRANSACTION") }} :
          </label>
          <input class="input tour-person__input filter-width mx10" type="text" v-model="filter.id" /> 
        </div>
        <div class="filter-item m-b-10 d-flex align-items-center">
          <label class="m-0 space-nowrap">
            {{ $t("REPORT_SALES.FORM_OF_PAYMENT") }} :
          </label>
          <vue-select 
            :options="filter.payment_type.options" 
            class="input cus-select mx10 no-uppercase-select payment-form_select"
            v-model="filter.payment_type.selected"
          >
          </vue-select> 
        </div>
      </div>
    </div>
    <button class="primary-btn search_btn" @click="filterReport(1)">{{ $t("REPORT_SALES.SEARCH") }}</button>

    <div class="table-content-part">
      <div class="d-flex justify-content-between">
        <div class="search-bar">
          <!--<p class="search-label">{{ $t("COMMON.SEARCH") }}</p>-->
          <!--<v-text-field v-model="search" single-line hide-details class="search-form"></v-text-field>-->
        </div>
        <div class="total-value">
          <span class="pr20">{{ $t("REPORT_SALES.CONSUMER_PRICE") }}: </span>  {{ reportData.total_price | formatPrice }}
          <span class="pr20">{{ $t("REPORT_SALES.COST_PRICE") }}: </span>  {{ reportData.total_business_price | formatPrice }}
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :items="reportData.data"
        class="cus-table editable-table sales-table striped-table"
        :single-expand="false"
        :expanded.sync="expanded"
        item-key="id"
        :loading="loading"
        :options.sync="options"
        :server-items-length="reportData.total"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
      >
        <template v-slot:[`item.transaction_id`]="{ item }">
          <div v-if="item.parent_transaction_id != null && item.price < 0" class="color-red4">
            {{ item.transaction_id }}
          </div>
          <div v-else>
            {{ item.transaction_id }}
          </div>
        </template>
        <template v-slot:[`item.business_name`]="{ item }">
          <div v-if="item.parent_transaction_id != null && item.price < 0" class="color-red4">
            {{ item.business_name }}
          </div>
          <div v-else>
            {{ item.business_name }}
          </div>
        </template>
        <template v-slot:[`item.phone_number`]="{ item }">
          <div v-if="item.parent_transaction_id != null && item.price < 0" class="color-red4">
            {{ item.phone_number }}
          </div>
          <div v-else>
            {{ item.phone_number }}
          </div>
        </template>
        <template v-slot:[`item.supplier_name`]="{ item }">
          <div class="ticket-item" :style="{'background-color': item.supplier_logo_background_color, 'color': item.supplier_font_color}">
            {{ item.supplier_name }}
          </div>
        </template>
        <template v-slot:[`item.product_name`]="{ item }">
          <div v-if="item.parent_transaction_id != null && item.price < 0" class="color-red4">
            <div v-if="item.supplier_id == suppliers.tourDeals">
              {{ item.dealTitle ? item.dealTitle : item.hotelName }}
            </div>
            <div v-else>
              {{ item['product_name_' + currentLang] }}
            </div>
          </div>
          <div v-else>
            <div v-if="item.supplier_id == suppliers.tourDeals">
              {{ item.dealTitle ? item.dealTitle : item.hotelName }}
            </div>
            <div v-else>
              {{ item['product_name_' + currentLang] }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          <div v-if="item.parent_transaction_id != null && item.price < 0" class="color-red4">
            {{getDateFromString(item.transaction_end_timestamp)}}
          </div>
          <div v-else>
            {{getDateFromString(item.transaction_end_timestamp)}}
          </div>
        </template>
        <template v-slot:[`item.hour`]="{ item }">
           <div v-if="item.parent_transaction_id != null && item.price < 0" class="color-red4">
            {{getTimeFromString(item.transaction_end_timestamp)}}
          </div>
          <div v-else>
            {{getTimeFromString(item.transaction_end_timestamp)}}
          </div>
        </template>
        <template v-slot:[`item.price`]="{ item }">
           <div v-if="item.parent_transaction_id != null && item.price < 0" class="color-red4">
            {{ item.price }}
          </div>
          <div v-else>
            {{ item.price }}
          </div>
        </template>
        <template v-slot:[`item.business_price`]="{ item }">
           <div v-if="item.parent_transaction_id != null && item.price < 0" class="color-red4">
            {{ item.business_price }}
          </div>
          <div v-else>
            {{ item.business_price }}
          </div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <template v-if="item.parent_transaction_id != null && item.price < 0">
            <div v-if="item.is_partly" class="ticket-item bg-red8">
              {{ $t("CANCELLATION.PARTIAL_CANCEL") }}
            </div>
            <div v-else class="ticket-item bg-grey4 color-red4">
              {{ $t("REPORT_SALES.CANCELLED") }}
            </div>
          </template>
          <div class="ticket-item bg-blue" v-else-if="item.status == 5">
            {{ $t("REPORT_SALES.PENDING") }}
          </div>
          <div class="ticket-item bg-green1" v-else-if="item.status == 3">
            {{ $t("REPORT_SALES.SUCCESS") }}
          </div>
          <div class="ticket-item bg-red" v-else-if="item.status == 4">
            {{ $t("REPORT_SALES.FAILED") }}
          </div>
          <!-- <div class="ticket-item bg-2" v-else-if="item.status == 1">
            {{ $t("REPORT_SALES.OPENDED") }}
          </div> -->
          <div class="ticket-item bg-1" v-else-if="item.status == 6">
            {{ $t("REPORT_SALES.CANCELLED") }}
          </div>
        </template>
        <template v-slot:[`item.payment_cancel_status`]="{ item }">
          <template v-if="item.parent_transaction_id == null && item.price >= 0">
            <div v-if="item.payment_cancel_status == 'NO'">
              <div
                class="ticket-item bg-blue3 pointer"
                v-if="[suppliers.ravkav, suppliers.ramiLevi].includes(item.supplier_id) && item.status == 3 && item.is_cancelable == true && item.is_canceled == false && item.supplier_id != null && item.price > 0"
                @click="openCancellationModal(item, true)"
              >
                {{ $t("REPORT_SALES.CANCEL_ACTION") }}
              </div>
              <div
                class="ticket-item bg-blue3 pointer"
                v-else-if="isCancelPermission && item.status == 3 && item.is_cancelable == true && item.is_canceled == false && item.supplier_id != null && item.price > 0"
                @click="openCancellationModal(item)"
              >
                {{ $t("REPORT_SALES.CANCEL_ACTION") }}
              </div>
              
              <div v-else>
                {{ $t("REPORT_SALES.NOT_CANCELLED") }}
              </div>
            </div>
            <div 
              class="ticket-item bg-yellow"
              v-else-if="item.payment_cancel_status == 'AWAITING'"
            >
              {{ $t("REPORT_SALES.AWAITING") }}
            </div>
            <div 
              class="ticket-item bg-red6"
              v-else-if="item.payment_cancel_status == 'DONE'"
            >
              {{ $t("REPORT_SALES.CANCELLED") }}
            </div>
            <div 
              class="ticket-item bg-grey"
              v-else-if="item.payment_cancel_status == 'REJECT'"
            >
              {{ $t("REPORT_SALES.REJECTED") }} 
            </div>
          </template>
          <template v-if="item.parent_transaction_id != null && item.price < 0">
            <div 
              class="ticket-item bg-grey3 color-primary"
            >
              {{ item.parent_transaction_id }} 
            </div>
          </template>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <img 
            src="/assets/img/print.png" 
            alt="" 
            class="print-img hover-pointer"
            @click="rowPrint(item)">
        </template>
        <template v-slot:[`item.expand`]="{ item }">
          <div v-if="item.supplier_type != suppliersTypes.prepaid || (item.parent_transaction_id != null && item.price < 0)">
            <v-btn small @click="expanded = [item]" v-if="!expanded.includes(item)">{{ $t('COMMON.MORE') }}</v-btn>
            <v-btn small @click="expanded = []" v-if="expanded.includes(item)">{{ $t('COMMON.LESS') }}</v-btn>
          </div>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="color-white" :style="{'background-color': item.supplier_logo_background_color, 'color': item.supplier_font_color}">
            <div v-if="item.parent_transaction_id != null && item.price < 0" class="expand-part">
              <div class="text-center">
                <p>{{ $t("CANCELLATION.CANCELED_PRICE") }}</p>
                <p>{{ Math.abs(item.price) || '-' }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("CANCELLATION.SALE_TRANSACTION_PRICE") }}</p>
                <p>{{ item.parent_transaction_price || '-' }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("CANCELLATION.CANCELLATION_NOTE") }}</p>
                <p>{{ item.cancellation_note || '-' }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("CANCELLATION.CANCEL_CONFIRMATION_NUMBER") }}</p>
                <p>{{ item.cancel_confirmation_number || '-'}}</p>
              </div>
            </div>
            <div v-else-if="item.supplier_type == suppliersTypes.tourism" class="expand-part">
              <template v-if="item.supplier_id == suppliers.tourDeals">
                <div class="text-center">
                  <p>{{ $t("REPORT_SALES.NUMBER_OF_TRANSACTION") }}</p>
                  <p>{{ item.id }}</p>
                </div>
                <div class="text-center">
                  <p>{{ $t("REPORT_SALES.EXTERNAL_ORDER_ID") }}</p>
                  <p>{{ item.external_code || '-' }}</p>
                </div>
                <div class="text-center">
                  <p>{{ $t("REPORT_SALES.TRANSACTION_TYPE") }}</p>
                  <p>{{ item.dealType || '-' }}</p>
                </div>
                <div class="text-center">
                  <p>{{ $t("REPORT_SALES.CUSTOMER_NAME") }}</p>
                  <p>{{ Array.isArray(item.persons) ? [].concat.apply([], item.persons).map(person => person.is_customer == 1 ? person.firstName + ' ' + person.lastName : null).filter(customer => customer != null).join(' | ') : item.customer_name }}</p>
                </div>
                <div class="text-center">
                  <p>{{ $t("TOURISM.TO") }}</p>
                  <p>{{ item['destinationName_' + currentLang] }}</p>
                </div>
                <div class="text-center">
                  <p>{{ $t("REPORT_SALES.HOTEL") }}</p>
                  <p>{{ item.hotelName || '-' }}</p>
                </div>
                <div class="text-center">
                  <p>{{ $t("REPORT_SALES.STARS") }}</p>
                  <p>{{ item.hotelRating || '-' }}</p>
                </div>
                <div class="text-center">
                  <p>{{ $t("REPORT_SALES.HOSTING") }}</p>
                  <p>{{ item.farebasis ? item.farebasis.join(' | ') : '-' }}</p>
                </div>
                <div class="text-center">
                  <p>{{ $t("REPORT_SALES.NUMBER_OF_PASSENGERS") }}</p>
                  <p>{{ item.paxcomplect ? item.paxcomplect.join('').length : '-' }}</p>
                </div>
                <router-link
                        :to="{ path: '/report-details/' + item.transaction_id, query: { phoneNumber: item.phone_number }}"
                >
                  <button class="full-detail_btn">{{ $t("REPORT_SALES.FOR_FULL_DETAILS") }}</button>
                </router-link>
              </template>
              <template v-if="item.supplier_id == suppliers.flights">
                <div class="text-center">
                  <p>{{ $t("REPORT_SALES.NUMBER_OF_TRANSACTION") }}</p>
                  <p>{{ item.id }}</p>
                </div>
                <div class="text-center">
                  <p>{{ $t("REPORT_SALES.EXTERNAL_ORDER_ID") }}</p>
                  <p>{{ item.flights_external_id || '-' }}</p>
                </div>
                <div class="text-center">
                  <p>{{ $t("FLIGHTS.TYPE") }}</p>
                  <p>{{ item.flights_type || '-' }}</p>
                </div>
                <div class="text-center">
                  <p>{{ $t("FLIGHTS.FROM") }}</p>
                  <p>{{ item.flights_city_from_name }}</p>
                </div>
                <div class="text-center">
                  <p>{{ $t("FLIGHTS.TO") }}</p>
                  <p>{{ item.flights_city_to_name }}</p>
                </div>
                <a :href="$router.resolve({name: 'FlightsOrder', params: { id: item.flights_order_id }}).href"
                   class="full-detail_btn">
                  {{ $t("REPORT_SALES.FOR_FULL_DETAILS") }}
                </a>
              </template>
            </div>
            <div v-else-if="item.supplier_type == suppliersTypes.gift_cards" class="expand-part">
              <div class="text-center">
                <p>{{ $t("REPORT_SALES.NUMBER_OF_TRANSACTION") }}</p>
                <p>{{ item.id }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("REPORT_SALES.EXTERNAL_ORDER_ID") }}</p>
                <p>{{ item.external_transaction_id || '-' }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("REPORT_SALES.GIFT_CARD_CODE") }}</p>
                <p>{{ item.external_code || '-' }}</p>
              </div>
            </div>
            <div v-else-if="item.supplier_type == suppliersTypes.games" class="expand-part">
              <div class="text-center">
                <p>{{ $t("REPORT_SALES.NUMBER_OF_TRANSACTION") }}</p>
                <p>{{ item.id }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("REPORT_SALES.EXTERNAL_ORDER_ID") }}</p>
                <p>{{ item.external_transaction_id || '-' }}</p>
              </div>
              <div class="text-center">
                <p>{{ item.supplier_id == suppliers.xboxOneId ? $t("REPORT_SALES.GAME_CARD_CODE") : $t("REPORT_SALES.GAME_CARD_CODE")}}</p>
                <p>{{ item.external_code || '-' }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("REPORT_SALES.SERIAL_NUMBER") }}</p>
                <p>{{ item.pin_code || '-' }}</p>
              </div>
            </div>
            <div v-else-if="item.supplier_id == suppliers.iecMatamId" class="expand-part">
              <div class="text-center">
                <p>{{ $t("REPORT_SALES.CONTRACT_NUMBER") }}</p>
                <p>{{ item.contract_number }}</p>
              </div>
            </div>
            <div v-else-if="item.supplier_id == suppliers.iecPayment" class="expand-part">
              <div class="text-center">
                <p>{{ $t("COMMON.ADDRESS") }}</p>
                <p>{{ item.client_address }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("SUPPLIER_PAYMENT.PURCHASE_AMOUNT") }}</p>
                <p>{{ item.external_price }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("SUPPLIER_PAYMENT.APPROVAL_NUMBER") }}</p>
                <p>{{ item.external_transaction_id }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("SUPPLIER_PAYMENT.LOADING_CODE") }}</p>
                <p class="direction-ltr">{{ item.external_code }}</p>
              </div>
            </div>
            <div v-else-if="item.supplier_id == suppliers.highway6Id" class="expand-part">
              <div class="text-center">
                <p>{{ $t("SUPPLIER_PAYMENT.ID_NUMBER") }}</p>
                <p>{{ item.customer_id }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("REPORT_SALES.BILL_NUMBER") }}</p>
                <p>{{ item.bill_number }}</p>
              </div>
            </div>
            <div v-else-if="item.supplier_id == suppliers.ravkav" class="expand-part" :class="{'expand-part': (item.payment_cancel_status === 'NO'), 'expand-part-red': !(item.payment_cancel_status === 'NO')}">
              <div class="text_center">
                <p>{{ $t("REPORT_SALES.NUMBER_OF_TRANSACTION") }}</p>
                <p>{{ item.id }}</p>
              </div>
              <div class="text_center">
                <p>{{ $t("REPORT_SALES.RAV_KAV_CARD_NUMBER") }}</p>
                <p>{{ item.card_number }}</p>
              </div>
              <div class="text_center">
                <p>{{ $t("REPORT_SALES.RAV_KAV_TRANSACTION") }}</p>
                <p>{{ item.confirmation }}</p>
              </div>
              <div v-if="item.contract_number" class="text_center">
                <p>{{ $t("REPORT_SALES.CONTRACT_NAME") }}</p>
                <p>{{ item.contract_number.split(",")[0] }}</p>
              </div>
              <div v-if="item.contract_number" class="text_center">
                <p>{{ $t("REPORT_SALES.CONTRACT_TYPE") }}</p>
                <p>{{ item.contract_number.split(",")[1] }}</p>
              </div>
            </div>
            <div v-else class="expand-part">
              <!-- <button class="full-detail_btn">{{ $t("REPORT_SALES.FOR_FULL_DETAILS") }}</button> -->
            </div>
          </td>
        </template>
      </v-data-table>

    </div>
    <div class="table-print-excel-part d-flex align-items-center">
      <div class="d-flex align-items-center" @click="printTableData">
        <img src="/assets/img/print.png" alt="" class="print-img hover-pointer mx15">
        <label for="">{{ $t("REPORT_SALES.FOR_PRINTING") }}</label>
      </div>
      <div class="d-flex align-items-center" @click="exportSalesReportHandler">
        <img src="/assets/img/excel.png" alt="" class="excel-img hover-pointer">
        <label for="">{{ $t("REPORT_SALES.EXPORT_TO_EXCEL") }}</label>
      </div>
    </div>
    <loading :active.sync="showLoadingAnimation" :can-cancel="false" :is-full-page="true"></loading>
    <CancellationModal 
      :cancellationItem="cancellationItem" 
      @refilter="filterReport(1)"
      :isRavkavModal="isRavkavModal"
      :isCancelPermission="isCancelPermission"
    ></CancellationModal>
  </main>
</template>

<script>
import "bootstrap";
import "../../common/plugins/bootstrap-vue";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { mapState, mapGetters, mapActions } from "vuex";

import CancellationModal from '../../components/report/CancellationModal.vue'

import { saveAs } from 'file-saver';
export default {
  name: "SalesReport",
  components: {
    CancellationModal
  },  
  computed: {
    ...mapState({
      loading: state => state.report.loading,
      exportInProgress: state => state.report.exportInProgress,
      reportData: state => state.report.salesReportData_receipts,
      printData: state => state.report.salesPrintData_receipts,
      supplier_items: state => state.supplier.supplier_items,
      contracts: state => state.report.salesDetails,
    }),
    ...mapGetters('permission', {
      hasApiAccess: 'hasApiAccess'
    }),
    showLoadingAnimation() {
      return this.loading || this.exportInProgress;
    },
    suppliersOptionsList() {
      let suppliersOptionsList = [];

      if (this.supplier_items) {
          for (let supplierType in this.supplier_items) {
              let supplierTypeItems = this.supplier_items[supplierType];
              for (let supplerData of supplierTypeItems) {
                  suppliersOptionsList.push({id: supplerData.id, name: supplerData.name});
              }
          }
      }
      if (suppliersOptionsList) {
        suppliersOptionsList.sort(function(a, b) {
          var nameA = a.name.toUpperCase(); // ignore upper and lowercase
          var nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
      }

      suppliersOptionsList.unshift({id: null, name: 'All'});

      return suppliersOptionsList;
    },
    statuses() {
      return {
        1: this.$t("REPORT_SALES.OPENDED"),
        2: '2',
        3: this.$t("REPORT_SALES.SUCCESS"),
        4: this.$t("REPORT_SALES.FAILED"),
        5: this.$t("REPORT_SALES.PENDING"),
        6: this.$t("REPORT_SALES.CANCELLED")
      };
    },
    fieldsToExport() {
      return [
        {
          name: this.$t("REGISTER_RESELLER.BUSINESS_NAME"),
          key: "business_name",
          field: "business_name"
        },
        {
          name: this.$t("REPORT_SALES.MOBILE"),
          key: "phone_number",
          field: "phone_number"
        },
        {
          name: this.$t("REPORT_SALES.SUPPLIER_ID"),
          key: "supplier_name",
          field: "supplier_name"
        },
        {
          name: this.$t("GIFTCARD_PAYMENT_RESULT.PRODUCT"),
          key: "product_name_" + this.currentLang,
          field: "product_name_" + this.currentLang
        },
        {
          name: this.$t("COMMON.DATE"),
          key: "transaction_date",
          field: "transaction_end_timestamp",
          dateFormat: "YYYY-MM-DD"
        },
        {
          name: this.$t("COMMON.HOUR"),
          key: "transaction_time",
          field: "transaction_end_timestamp",
          dateFormat: "HH:mm:ss"
        },
        {
          name: this.$t("REPORT_SALES.CONSUMER_PRICE"),
          key: "price",
          field: "price"
        },
        {
          name: this.$t("REPORT_SALES.COST_PRICE"),
          key: "business_price",
          field: "business_price"
        },
        {
          name: this.$t("REPORT_SALES.STATUS"),
          key: "status",
          field: "status",
          options: this.statuses
        },
        {
          name: this.$t("REPORT_SALES.REQUEST_FOR_CANCELLATION"),
          key: "payment_cancel_status",
          field: "payment_cancel_status"
        }
      ];
    },
    isCancelPermission() {
      return this.hasApiAccess('/payment/cancel', 'POST');
    }
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 6);
    return {
      search: "",
      visible: false,
      headers: [
        {
          text: this.$t("CANCELLATION.TRANSACTION_NUMBER"),
          align: "center",
          sortable: false,
          value: "transaction_id"
        },
        {
          text: this.$t("REGISTER_RESELLER.BUSINESS_NAME"),
          align: "center",
          sortable: false,
          value: "business_name"
        },
        {
          text: this.$t("REPORT_SALES.MOBILE"),
          align: "center",
          sortable: false,
          value: "phone_number"
        },
        {
          text: this.$t("REPORT_SALES.SUPPLIER_ID"),
          align: "center",
          sortable: false,
          value: "supplier_name"
        },
        {
          text: this.$t("GIFTCARD_PAYMENT_RESULT.PRODUCT"),
          align: "center",
          sortable: false,
          value: "product_name"
        },
        {
          text: this.$t("COMMON.DATE"),
          align: "center",
          sortable: false,
          value: "date"
        },
        {
          text: this.$t("COMMON.HOUR"),
          align: "center",
          sortable: false,
          value: "hour"
        },
        {
          text: this.$t("REPORT_SALES.CONSUMER_PRICE"),
          align: "center",
          sortable: false,
          value: "price"
        },
        {
          text: this.$t("REPORT_SALES.COST_PRICE"),
          align: "center",
          sortable: false,
          value: "business_price"
        },
        {
          text: this.$t("REPORT_SALES.STATUS"),
          align: "center",
          sortable: false,
          value: "status"
        },
        {
          text: this.$t("REPORT_SALES.REQUEST_FOR_CANCELLATION"),
          align: "center",
          sortable: false,
          value: "payment_cancel_status"
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "action"
        },
        // { 
        //   text: '', 
        //   value: 'data-table-expand',
        //   align: "center" 
        // },
        { text: '', 
          value: 'expand', 
          align:'center'
        }
      ],
      filter: {
        isTimeLimit: false,
        supplier_id: null,
        id: "",
        phone: "",
        // dateRange: { startDate, endDate },
        payment_type: {
          selected: {label: "All", code: null},
          options: [
            {label: "All", code: null},
            {label: "CC", code: "cc"},
            {label: "Credit", code: "credit"},
            {label: "Cash", code: "cash"}
          ]
        },
        dateRange: {
          selected: {label: "None", code: 0},
          options: [
            {label: "Current month", code: 1}, 
            {label: "Last month", code: 2} 
          ]
        },
        start_date: "",
        end_date: "",
        start_hours: {
          options: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
          selected: "00"
        },
        start_mins: {
          options: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"],
          selected: "00"
        },
        end_hours: {
          options: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
          selected: "00"
        },
        end_mins: {
          options: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"],
          selected: "00"
        },

      },
      
      format: "d MMMM yyyy",
      expanded: [],
      suppliers: {
        iecMatamId: 186,
        highway6Id: 187,
        tourDeals: 111,
        flights: 1111,
        amazonId: 1000,
        playStationId: 1001,
        xboxOneId: 1002,
        steamId: 1006,
        googlePlayId: 1009,
        razerGoldId: 1010,
        netflixId: 1011,
        iTunesId: 1012,
        iecPayment: 1013,
        ravkav: 999,
        we4G: 1100,
        ramiLevi: 1101,
      },
      suppliersTypes: {
        prepaid: 1,
        bills: 2,
        manual_cards: 3,
        gift_cards: 4,
        tourism: 5,
        games: 6
      },
      itemsPerPageOptions: [10, 15, 25, 50, 100],
      options: {
        itemsPerPage: 10,
        page: 1
      },
      filtersInUse: {},
      cancellationItem: {},
      isRavkavModal: false
    };
  },
  methods: {
    ...mapActions("report", {
      filter_sales_report: "filter_sales_report",
      filter_sales_report_print: "filter_sales_report_print",
      export_sales_report: "export_sales_report",
      cancel_payment: "cancel_payment"
    }),
    ...mapActions('supplier', {
      getAllSupplierItems: 'getAll'
    }),

    getDateFromString(dateString) {
      if(dateString) {
        return dateString.substring(0, 10);
      }
    },
    getTimeFromString(dateString) {
      if(dateString) { 
        return dateString.substring(11, 19);
      } 
    },
    changeDateRange() {
      let today = new Date();
      let month = today.getMonth();
      let year = today.getFullYear();
      if (this.filter.dateRange.selected.code == 1) {
        this.filter.start_date = new Date(year, month, 1);
        this.filter.end_date = new Date(year, month + 1, 0);
        this.filter.start_hours.selected = "00"
        this.filter.start_mins.selected = "00"
        this.filter.end_hours.selected = "23"
        this.filter.end_mins.selected = "59"
      } else if (this.filter.dateRange.selected.code == 2) {
        if (month == 0) {
          year = year - 1;
          month = 12;
        }
        this.filter.start_date = new Date(year, month - 1, 1);
        this.filter.end_date = new Date(year, month, 0);
        this.filter.start_hours.selected = "00";
        this.filter.start_mins.selected = "00";
        this.filter.end_hours.selected = "23";
        this.filter.end_mins.selected = "59";
      }
    },

    prepareFilters() {
      let start_date = "";
      let end_date = "";
      if (this.filter.start_date != "") {
        let start_year = this.filter.start_date.getFullYear();
        let start_month = (this.filter.start_date.getMonth() + 1).toString().padStart(2, '0');
        let start_day = this.filter.start_date.getDate().toString().padStart(2, '0');
        start_date = start_year + "-" + start_month + "-" + start_day + "T" + this.filter.start_hours.selected + ":" + this.filter.start_mins.selected + ":00";
      }

      if (this.filter.end_date != "") {
        let end_year = this.filter.end_date.getFullYear();
        let end_month = (this.filter.end_date.getMonth() + 1).toString().padStart(2, '0');
        let end_day = this.filter.end_date.getDate().toString().padStart(2, '0');
        end_date = end_year + "-" + end_month + "-" + end_day + "T" + this.filter.end_hours.selected + ":" + this.filter.end_mins.selected + ":00";
      }
      const filters = {
        start: start_date,
        end: end_date,
        payment_type: this.filter.payment_type.selected.code,
        id: this.filter.id,
        supplier_id: this.filter.supplier_id,
        phone: this.filter.phone
      }

      return filters;
    },
    filterReport(page) {
      page = +page || +this.options.page;
      this.options.page = page;
      let itemsPerPage = +this.options.itemsPerPage;
      let skip = (page - 1) * itemsPerPage;
      if (page === 1) {
        this.filtersInUse = this.prepareFilters()
      }
      const filters = this.filtersInUse;
      this.filter_sales_report({limit: itemsPerPage, skip: skip, filters: filters});
    },
     
    /* print one row */ 
    rowPrint(item) {
      item.date = this.getDateFromString(item.transaction_end_timestamp);
      item.hour = this.getTimeFromString(item.transaction_end_timestamp);

      let defaultProperties = [
        { field: 'payment_cancel_status', displayName: this.$t("REPORT_SALES.REQUEST_FOR_CANCELLATION") },
        { field: 'status', displayName: this.$t("REPORT_SALES.STATUS") },
        { field: 'business_price', displayName: this.$t("REPORT_SALES.COST_PRICE") },
        { field: 'price', displayName: this.$t("REPORT_SALES.CONSUMER_PRICE") },
        { field: 'hour', displayName: this.$t("COMMON.HOUR") },
        { field: 'date', displayName: this.$t("COMMON.DATE") },
        { field: 'product_name_' + this.currentLang, displayName: this.$t("GIFTCARD_PAYMENT_RESULT.PRODUCT") },
        { field: 'supplier_name', displayName: this.$t("REPORT_SALES.SUPPLIER_ID") },
        { field: 'phone_number', displayName: this.$t("REPORT_SALES.MOBILE") },
        { field: 'business_name', displayName: this.$t("REGISTER_RESELLER.BUSINESS_NAME") }
      ]

      let addtionalProperties = [];
      if (item.supplier_type == this.suppliersTypes.tourism) {
        if (item.supplier_id == this.suppliers.tourDeals) {
            if (!item.external_code) {
                item.external_code = '-';
            }
            if (!item.dealType) {
                item.dealType = '-';
            }
            if (Array.isArray(item.persons)) {
                item.updated_customer_name = [].concat.apply([], item.persons).map(person => person.is_customer == 1 ? person.firstName + ' ' + person.lastName : null).filter(customer => customer != null).join(' | ')
            } else {
                item.updated_customer_name = item.customer_name;
            }
            if (!item.hotelName) {
                item.hotelName = '-';
            }
            if (!item.hotelRating) {
                item.hotelRating = '-';
            }
            if (item.farebasis) {
                item.farebasis = item.farebasis.join(' | ');
            } else {
                item.farebasis = '-';
            }
            if (item.paxcomplect) {
                item.paxcomplect = item.paxcomplect.join('').length;
            } else {
                item.paxcomplect = '-';
            }

            addtionalProperties = [
                { field: 'id', displayName: this.$t("REPORT_SALES.NUMBER_OF_TRANSACTION") },
                { field: 'external_code', displayName: this.$t("REPORT_SALES.EXTERNAL_ORDER_ID") },
                { field: 'dealType', displayName: this.$t("REPORT_SALES.TRANSACTION_TYPE") },
                { field: 'updated_customer_name', displayName: this.$t("REPORT_SALES.CUSTOMER_NAME") },
                { field: 'destinationName_' + this.currentLang, displayName: this.$t("TOURISM.TO") },
                { field: 'hotelName', displayName: this.$t("REPORT_SALES.HOTEL") },
                { field: 'hotelRating', displayName: this.$t("REPORT_SALES.STARS") },
                { field: 'farebasis', displayName: this.$t("REPORT_SALES.HOSTING") },
                { field: 'paxcomplect', displayName: this.$t("REPORT_SALES.NUMBER_OF_PASSENGERS") }
            ]
        }
        if (item.supplier_id == this.suppliers.flights) {

        }
      } else if (item.supplier_type == this.suppliersTypes.gift_cards) {
        if (!item.external_transaction_id) {
          item.external_transaction_id = '-';
        }
        if (!item.external_code) {
          item.external_code = '-';
        }
        addtionalProperties = [
          { field: 'id', displayName: this.$t("REPORT_SALES.NUMBER_OF_TRANSACTION") },
          { field: 'external_transaction_id', displayName: this.$t("REPORT_SALES.EXTERNAL_ORDER_ID") },
          { field: 'external_code', displayName: this.$t("REPORT_SALES.GIFT_CARD_CODE") }
        ]
      } else if (item.supplier_id == this.suppliers.iecMatamId) {
        addtionalProperties = [
          { field: 'contract_number', displayName: this.$t("REPORT_SALES.CONTRACT_NUMBER") }
        ]
      } else if (item.supplier_id == this.suppliers.highway6Id) {
        addtionalProperties = [
          { field: 'customer_id', displayName: this.$t("SUPPLIER_PAYMENT.ID_NUMBER") },
          { field: 'bill_number', displayName: this.$t("REPORT_SALES.BILL_NUMBER") }
        ]
      }

      let lineProperties = [ ...defaultProperties, ...addtionalProperties ];
      const printData = [];
      printData.push(item);
      printJS({
        printable: printData, 
        properties: lineProperties,
        type: 'json'
      })
    },
    /* print table */ 
    async printTableData() {
      const filters = this.filtersInUse;
      const totalRowsNumber = this.reportData.total;
        this.filter_sales_report_print({filters: filters, total: totalRowsNumber}).then(
          result => {
            printJS({
              printable: result, 
              properties: [
                { field: 'payment_cancel_status', displayName: this.$t("REPORT_SALES.REQUEST_FOR_CANCELLATION") },
                { field: 'status', displayName: this.$t("REPORT_SALES.STATUS") },
                { field: 'business_price', displayName: this.$t("REPORT_SALES.COST_PRICE") },
                { field: 'price', displayName: this.$t("REPORT_SALES.CONSUMER_PRICE") },
                { field: 'hour', displayName: this.$t("COMMON.HOUR") },
                { field: 'date', displayName: this.$t("COMMON.DATE") },
                { field: 'product_name_' + this.currentLang, displayName: this.$t("GIFTCARD_PAYMENT_RESULT.PRODUCT") },
                { field: 'supplier_name', displayName: this.$t("REPORT_SALES.SUPPLIER_ID") },
                { field: 'phone_number', displayName: this.$t("REPORT_SALES.MOBILE") },
                { field: 'business_name', displayName: this.$t("REGISTER_RESELLER.BUSINESS_NAME") }
              ],
              type: 'json',
              gridHeaderStyle: this.currentLang == 'en' ? 'direction: ltr; border: 1px solid #3971A5;' : 'direction: rtl; border: 1px solid #3971A5;',
              gridStyle: this.currentLang == 'en' ? 'direction: ltr; border: 1px solid #3971A5;' : 'direction: rtl; border: 1px solid #3971A5;'
            })
          },
          error => {
          }
        );
    },

    exportSalesReportHandler(event) {
      if (!this.reportData || !this.reportData.total) {
        return false;
      }
      const filters = this.filtersInUse;
      const fields = this.fieldsToExport;
      this.export_sales_report({format: 'excel', fields: fields, filters: filters}).then(
        result => {
          saveAs(result, 'sales_report_' + moment().format('YYYY-MM-DD') + '.xlsx');
        },
        error => {
        }
      );
    },
    openCancellationModal(item, isRavkav=false) {
      this.isRavkavModal = isRavkav;
      this.cancellationItem = item;
      this.$modal.show('cancellationModal');
    }
  },
  watch: {
    options: {
      handler () {
          this.filterReport();
      },
      deep: true,
    },
  },
  mounted() {
    this.filter.start_date = new Date();
  },
  created() {
    this.getAllSupplierItems();
  }
};
</script>

<style lang="scss">
.time-select{
  width: 70px;
  .vs__selected {
    height: 100%;
    top: 0;
  }
  .vs__dropdown-menu {
    min-width: 70px;
    max-width: 70px;
  }
}
.payment-form_select {
  min-width: 170px;
}
.cus-select {
  ul {
    padding-left: 0;
  }
} 
.inline-checkbox .v-input__control {
  height: 100%;
}
.sales2-range-date {
  display: flex;
  align-items: center;
  label {
    margin:0 20px 0 0;
  }
  .range-datepicker {
    width: 260px;
    .reportrange-text {
      border-radius: 8px;
      padding: 0 10px;
      height: 30px;
      span {
        font-size: 18px;
        color: #002036;
      }
    }
  }
}
.text_center {
  text-align: center;
  float: right;
  padding-left: 100px;
}
.position {
  display: contents;
}

</style>
<style scoped lang="scss">
.direction-ltr {
  direction: ltr !important;
}
.translation {
  height: 100%;
  color: #002036;
}

.lang-title {
  font-size: 30px;
  margin-top: 10px;
  text-align: center;
}

.filter-part {
  border: 1px solid #707070;
  margin: 20px 50px 0 50px;
  padding: 10px 15px 30px;
  .input {
    height: 30px;
    padding: 0 10px;
    border-radius: 8px;
  }
  .calendar-img {
    width: 25px;
    position: absolute;
    right: 12px;
    left: unset;
  }
  .v-input__control {
    height: 100%;
  }
  [class*="col-"] {
    padding: 0 !important;
  }
  .row {
    margin: 0;
  }
  .time-part {
    margin-left: 50px;
    margin-right: 0;
    min-width: 300px;
    .vue-select {
      padding: 0 4px;
    }
  }
  .range-part {
    margin-right: 50px;
  }
  .filter-search_icon {
    position: absolute;
    left: 14px;
    width: 20px;
    top: 5px;
  }
  .filter-item {
    margin-left: 0;
    margin-right: 20px;
    
  }
}

.total-value {
  font-weight: 600;
  color: #707070;
  font-size: 22px;
  span {
    font-size: 18px;
    color: #002036;
  }
}
.sales-table {
  .ticket-item {
    border: 1px solid #707070;
    color: #fff;
    border-radius: 5px;
    width: 70px;
    margin: 0 auto;
    text-align: center;
    &.expand-ticket-item {
      width: auto;
      padding: 0 5px;
    }
  }
  .print-img {
    width: 40px;
  }
}
.label-align {
  justify-content: flex-end;
}
.icon-div {
  background-color: #002036;
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.search_btn {
  margin: 0 auto;
  margin-top: 20px;
}
.expand-part {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0 10px;
  div p:before {
    content: ' ';
    display: inline-block;
  }
  p {
    margin: 10px 0;
  }
  .full-detail_btn {
    background-color: #fff;
    color: #000;
    font-weight: 700;
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 7px 20px;
  }
}
.expand-part-red {
  align-items: center;
  justify-content: space-around;
  padding: 0 10px;
  color: red;
  p {
    margin: 10px 0;
  }
  .full-detail_btn {
    background-color: #fff;
    color: #000;
    font-weight: 700;
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 7px 20px;
    margin: 10px 0;
  }
}
.rtl-type {
  label-align {
    justify-content: flex-start;
  }
  .excel-img {
    margin-right: 50px;
    margin-left: 15px;
  }
  .filter-part {
    .filter-item {
      margin-left: 20px;
      margin-right: 0;
    }
    .time-part {
      margin-right: 50px;
      margin-left: 0;
    }
    .calendar-img {
      left: 12px;
      right: unset;
    }
  }
  .sales2-range-date label {
    margin: 0 0 0 20px;
  }
  .time-select .vs__actions {
    margin-right: -10px;
  }
}
@media screen and (max-width: 1548px){
  .filter-part {
    .range-part {
      margin-right: 0;
      margin-left: 0;
    }
  } 
}
@media screen and (max-width: 768px){
  .filter-part {
    .time-part {
      margin-right: 0;
      margin-left: 0;
    }
  } 
}
@media screen and (max-width: 480px){
  .filter-part {
    margin: 20px 20px 0;
    .filter-item {
      width: 100%;
      flex-wrap: wrap;
      margin: 10px 0 0 !important;
      label {
        width: 100%;
      }
      .input {
        width: 100%;
        margin: 0 !important;
      }
      .icon-div {
        margin-left: 10px;
        margin-right: 0;
      }
    }
  }
  .search_btn {
    max-width: 80%;
  }
  .rtl-type {
    .icon-div {
      margin-right: 10px;
      margin-left: 0;
    }
  }
}
@media screen and (min-width: 992px){
  .filter-width {
    max-width: 170px;
  }
}
@media screen and (max-width: 992px){
  .label-align {
    justify-content: flex-start;
    padding-bottom: 0 !important;
  }
  .rtl-type .label-align {
    justify-content: flex-end;
    padding-bottom: 0 !important;
  }
}
</style>